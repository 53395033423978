
@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("CalibreBold"),
    url("./fonts/CalibreBold.otf") format("opentype");
}

@font-face {
  font-family: "Medium";   /*Can be any text*/
  src: local("CalibreMedium"),
    url("./fonts/CalibreMedium.otf") format("opentype");
}

@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("CalibreRegular"),
    url("./fonts/CalibreRegular.otf") format("opentype");
}

@font-face {
  font-family: "Semibold";   /*Can be any text*/
  src: local("CalibreSemibold"),
    url("./fonts/CalibreSemibold.otf") format("opentype");
}
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Bold'; */
  /* background-color: #f0f0f0; */
}

body {
  background-color: #242424; /* Replace with your desired background color */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
